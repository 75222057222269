import { Circle, Square } from "@mui/icons-material";
import { Box, Container, Divider, Grid, Typography, createTheme, useMediaQuery } from "@mui/material";
import { getDesignTokens } from "AppGlobals";
import React, { ReactNode } from "react";

type TimelineEvent = {
    order: Number,
    date: string,
    image: string,
    description: string,
    link: string
}

type TimelineProperties = {
    title?: string | undefined,
    events: TimelineEvent[]
}

export const TimelineWidget:React.FC<TimelineProperties> = ({ title, events }) => {
   
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Update the theme only if the mode changes
    const theme = React.useMemo(() => createTheme(
        getDesignTokens(
            prefersDarkMode ? "dark" : "light")
        ),
        [
            prefersDarkMode ? "dark" : "light"
        ]
    );
    
    return (
        <Container>
            <Grid sx={ {marginBottom: "40px"} } container>
            {
                events.map((event) => {
                    return <>
                        <Grid sx={{ minHeight: "50px"}} item xs={1}>
                            <Box sx={{backgroundColor: "#333", width: "1px", height: "100%", paddingTop: "20px"}}>
                            </Box>
                        </Grid>
                        <Grid sx={{ paddingBottom: "30px"} } item xs={11}>
                            <h3>{ event.date }</h3>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Box sx={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: event.description }}>
                                    </Box>
                                    { event.link == "" ? "" : 
                                        <Box sx={{ width: "100%" }}>
                                            <a href={ event.link }>{ event.link }</a> 
                                        </Box>
                                    }
                                </Grid>
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={5}>
                                    <a href={ event.link }><Box sx={{ width: "100%" }} component="img" src={event.image}></Box></a>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider flexItem sx={{ width: "100%", backgroundColor: "#333" }}></Divider>
                    </>
                })
            }
            </Grid>
        </Container>
    );
}