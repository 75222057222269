import { Box, Container } from "@mui/material";
import { QuoteSplash, QuoteSplashSubtitle, QuoteSplashTitle } from "layout/QuoteSplash"
import React, { useState, useEffect } from "react"


// update these strings to match QR code url :exhibit_id targets
// /scavenger_hunt/:exhibit_id

enum ScavengerHuntQRCodes {
  zero = "start",
  one = "04_15_gqmdqyowzt",
  two = "04_15_xewfiqelod",
  three = "04_15_acdqjpqlfm",
  reset = "reset",
  validate = "validate"
}

let scavengerHuntItems = [
  ScavengerHuntQRCodes.zero,
  ScavengerHuntQRCodes.one,
  ScavengerHuntQRCodes.two,
  ScavengerHuntQRCodes.three
]

type ScavengerHuntItem = {
  content: string,
  title: string,
  link: string,
  image: string,
  previousId: ScavengerHuntQRCodes | null
};

type ScavengerHuntMap = {
  [key in ScavengerHuntQRCodes]: ScavengerHuntItem
}

const scavengerHuntSequence: ScavengerHuntMap = {
  [ScavengerHuntQRCodes.zero]: {
    content: "Come to this spot and stay for a bit, and you'll walk away looking stylish.",
    title: "Clue One",
    link: "https://www.facebook.com/people/Lucky-Barber-Shop/100061088050369/?ref=py_c",
    image: "",
    previousId: null
  },
  [ScavengerHuntQRCodes.one]: {
    content: "Find your fresh sausages here! This location is Swan's Market's oldest tenant.",
    title: "Clue Two",
    link: "https://swansmarket.com/tenant/taylors-sausage/",
    image: "",
    previousId: ScavengerHuntQRCodes.zero
  },
  [ScavengerHuntQRCodes.two]: {
    content: "Here, you'll find free creative writing and bookmaking workshops, publishing opportunities for youth, in-school book projects, field trips, youth open mics, and more!",
    title: "Clue Three",
    link: "https://www.chapter510.org",
    image: "",
    previousId: ScavengerHuntQRCodes.one
  },
  [ScavengerHuntQRCodes.three]: {
    content: "You've found all three items in the scavenger hunt! Head to the Museum of Art and Digital Entertainment (MADE) at 921 Washington Street to pick up your prize!",
    title: "Congratulations",
    link: "https://www.themade.org",
    image: "",
    previousId: ScavengerHuntQRCodes.two
  },
  [ScavengerHuntQRCodes.reset]: {
    content: "Resetting",
    title: "",
    link: "",
    image: "",
    previousId: ScavengerHuntQRCodes.reset
  },
  [ScavengerHuntQRCodes.validate]: {
    content: "Scavenger hunt status:",
    title: "MADE Scavenger Hunt Validation",
    link: "",
    image: "",
    previousId: ScavengerHuntQRCodes.validate
  },
};

const ScavengerHuntItem = ({ exhibit_id }: { exhibit_id: string }) => {
  const huntItem = scavengerHuntSequence[exhibit_id];
  const [hasSeenPrevious, setHasSeenPrevious] = useState(false);
  const [hasFinished, setHasFinished] = useState(false);
  const [currentQRCode, setCurrentQRCode] = useState<string | null>(null);

  useEffect(() => {
    var _currentQRCode:string | null = null;
    if (!huntItem.previousId || localStorage.getItem(huntItem.previousId)) {
      setHasSeenPrevious(true);
      localStorage.setItem(exhibit_id, 'true');
    }
    if (localStorage.getItem(ScavengerHuntQRCodes.zero) != null) {
      _currentQRCode = ScavengerHuntQRCodes.zero
    }
    if (localStorage.getItem(ScavengerHuntQRCodes.one) != null) {
      _currentQRCode = ScavengerHuntQRCodes.one
    } 
    if (localStorage.getItem(ScavengerHuntQRCodes.two) != null) {
      _currentQRCode = ScavengerHuntQRCodes.two
    } 
    if (localStorage.getItem(ScavengerHuntQRCodes.three) != null) {
      _currentQRCode = ScavengerHuntQRCodes.three
      setHasFinished(true);
    }
    setCurrentQRCode(_currentQRCode);
    
    return () => { };
  }, []);

  if (hasSeenPrevious) {
    return (
      <>
        <QuoteSplash smallImage="" bigImage="">
          <QuoteSplashTitle>{ huntItem.title }</QuoteSplashTitle>
          <QuoteSplashSubtitle>
            <p>
              { huntItem.content }
            </p>
          </QuoteSplashSubtitle>
        </QuoteSplash>
        <Container>
            <p>
              Lost? Get some help with the <a href="https://app.gesso.fm/home/new-york/collections/oakland-belonging-the-voices-of-swan-s-market/">AUDIO TOUR</a>.
            </p>
            <p>
              If you're short on time, go directly to the location's <a href={ huntItem.link }>WEBSITE</a>.
            </p>
            <p>Scavenger hunt brought to you by:</p>
            <a href="https://www.chapter510.org">
              <Box sx={ { background: "#fff", padding: "10px", width: "100px", borderRadius: "10px"} } component={ "img" } src="/img/icon/chapter510.png" />
            </a> &nbsp;
            <a href="https://www.themade.org">
              <Box sx={ { background: "#fff", padding: "10px", width: "100px", borderRadius: "10px"} } component={ "img" } src="/logo512.png" />
            </a>
        </Container>
      </>
    );
  } else {
    if (exhibit_id === ScavengerHuntQRCodes.reset) {
      localStorage.removeItem(ScavengerHuntQRCodes.zero);
      localStorage.removeItem(ScavengerHuntQRCodes.one);
      localStorage.removeItem(ScavengerHuntQRCodes.two);
      localStorage.removeItem(ScavengerHuntQRCodes.three);
      return (
        <>
          <QuoteSplash smallImage="" bigImage="">
            <QuoteSplashTitle>Reset Exhibits</QuoteSplashTitle>
            <QuoteSplashSubtitle>
              All exhibit states reset.
            </QuoteSplashSubtitle>
          </QuoteSplash>
          <p>Scavenger hunt brought to you by:</p>
          <a href="https://www.chapter510.org">
            <Box sx={ { background: "#fff", padding: "10px", minHeight: "100px", width: "100px", borderRadius: "10px"} } component={ "img" } src="/img/icon/chapter510.png" />
          </a> &nbsp;
          <a href="https://www.themade.org">
            <Box sx={ { background: "#fff", padding: "10px", width: "100px", borderRadius: "10px"} } component={ "img" } src="/logo512.png" />
          </a>
        </>
      )
    } else if (exhibit_id === ScavengerHuntQRCodes.validate) {
      return (
        <>
          <QuoteSplash smallImage="" bigImage="">
            <QuoteSplashTitle>{ huntItem.title }</QuoteSplashTitle>
            <QuoteSplashSubtitle>
              { huntItem.content }
              { hasFinished? <p>Scavenger hunt completed!</p> : <p>Scavenger hunt incomplete!</p> }
            </QuoteSplashSubtitle>
          </QuoteSplash>
          <p>Scavenger hunt brought to you by:</p>
          <a href="https://www.chapter510.org">
            <Box sx={ { background: "#fff", padding: "10px", width: "100px", borderRadius: "10px"} } component={ "img" } src="/img/icon/chapter510.png" />
          </a> &nbsp;
          <a href="https://www.themade.org">
            <Box sx={ { background: "#fff", padding: "10px", width: "100px", borderRadius: "10px"} } component={ "img" } src="/logo512.png" />
          </a>
        </>
      );
    } else {
      return (
        <>
          <QuoteSplash smallImage="" bigImage="">
            <QuoteSplashTitle>You Haven't Finished the Last Clue!</QuoteSplashTitle>
            <QuoteSplashSubtitle>
              Looks like you're still on another clue, or haven't started at the beginning! Click the following link to take a look at the hint again:
              <a href={ "/scavenger_hunt/" + (currentQRCode === null ? "" : currentQRCode) }>Previous Clue</a>
            </QuoteSplashSubtitle>
          </QuoteSplash>
          <p>Scavenger hunt brought to you by:</p>
          <a href="https://www.chapter510.org">
            <Box sx={ { background: "#fff", padding: "10px", width: "100px", borderRadius: "10px"} } component={ "img" } src="/img/icon/chapter510.png" />
          </a> &nbsp;
          <a href="https://www.themade.org">
            <Box sx={ { background: "#fff", padding: "10px", width: "100px", borderRadius: "10px"} } component={ "img" } src="/logo512.png" />
          </a>
        </>
      )
    }
  }
}

const InvalidScavengerLanding = () => {
  return (
    <>
      Invalid URL
    </>
  )
}

const DefaultScavengerLanding = () => {
  return (
    <>
      <QuoteSplash smallImage="" bigImage="">
        <QuoteSplashTitle>
          Scavenger Hunt!
        </QuoteSplashTitle>
        <QuoteSplashSubtitle>
          Welcome to a scavenger hunt collaboration between the MADE and Chapter 510!
          <p>
            Use the <a href="https://gesso.fm/collections/oakland-belonging-the-voices-of-swan-s-market">Oakland Belonging: The Voices of Swan's Market Audio Tour</a> to help you find QR codes all around Old Oakland! With each QR Code scanned, you'll get another clue. Once you finish, swing by the MADE at 921 Washington Street for a prize! Keep in mind that the hunt is linear; you can't move on to the next clue until you solve the one you're on first!
          </p>
          <p>
            <a href="/scavenger_hunt/start">Start Here!</a>
          </p>
        </QuoteSplashSubtitle>
      </QuoteSplash>
    </>
  )
}

export const ScavengerHuntWidget = ({ exhibit_id }) => {
  if (!exhibit_id) return <DefaultScavengerLanding />;

  if (!scavengerHuntSequence[exhibit_id]) {
    console.error('unknown scavenger hunt exhibit_id');
    return <InvalidScavengerLanding />;
  }

  return <ScavengerHuntItem exhibit_id={exhibit_id} />;
}
