import { Color, PaletteMode, createTheme } from "@mui/material";
import { red } from '@mui/material/colors';

export const MADELight: Color | undefined = {
    50: '#d4d4d4',
    100: '#d8d8d8',
    200: '#e0e0e0',
    300: '#e4e4e4',
    400: '#e8e8e8',
    500: '#ebebeb',
    600: '#f4f4f4',
    700: '#f8f8f8',
    800: '#fbfbfb',
    900: '#ffffff',
    A100: '#333',
    A200: '#333',
    A400: '#333',
    A700: '#333'
}

export const MADELightSecondary: Color | undefined = {
    50: '#d4d4d4',
    100: '#d8d8d8',
    200: '#e0e0e0',
    300: '#e4e4e4',
    400: '#e8e8e8',
    500: '#151515',
    600: '#f4f4f4',
    700: '#f8f8f8',
    800: '#fbfbfb',
    900: '#ffffff',
    A100: '#e0e0e0',
    A200: '#e0e0e0',
    A400: '#e0e0e0',
    A700: '#c0c0c0'
}
  
export const MADEDark: Color | undefined = {
    50: '#050505',
    100: '#080808',
    200: '#0e0e0e',
    300: '#111111',
    400: '#131313',
    500: '#151515',
    600: '#1f1f1f',
    700: '#242424',
    800: '#282828',
    900: '#2f2f2f',
    A100: '#f00',
    A200: '#f00',
    A400: '#f00',
    A700: '#f00'
}

export const MADEDarkSecondary: Color | undefined = {
    50: '#050505',
    100: '#080808',
    200: '#0e0e0e',
    300: '#111111',
    400: '#131313',
    500: '#151515',
    600: '#1f1f1f',
    700: '#242424',
    800: '#282828',
    900: '#2f2f2f',
    A100: '#222',
    A200: '#333',
    A400: '#444',
    A700: '#555'
}

export const getDesignTokens:(mode: PaletteMode) => any = (mode: PaletteMode) => (
    {
        palette: {
            mode,
            ...(mode === 'light'
            ? {
                // palette values for light mode
                primary: MADELight,
                secondary: MADELightSecondary,
                background: {
                    default: MADELight[600],
                    paper: MADELight[900]
                }
            }
            : {
                // palette values for dark mode
                primary: MADEDark,
                secondary: MADEDarkSecondary,
                background: {
                    default: MADEDark[300],
                    paper: MADEDark[900],
                }
            }),
        },
        components: {
            MuiLink: {
                ...(mode === 'light' ?
                    { 
                    styleOverrides: {
                        root: { // Name of the rule
                            color: "#03e", // Some CSS
                        },
                    } 
                    } : {
                    styleOverrides: {
                        root: { // Name of the rule
                            color: "#0ef", // Some CSS
                        },
                    } 
                    }
                )
            },
            MuiPopover: {
                ...(mode === 'light' ?
                    { 
                    styleOverrides: {
                        root: { // Name of the rule
                        color: MADEDark[500], // Some CSS
                        },
                    } 
                    } : {
                    styleOverrides: {
                        root: { // Name of the rule
                        color: MADELight[500], // Some CSS
                        },
                    } 
                    }
                )
            }
        }
    }
);