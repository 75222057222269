import React, { ReactNode } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { theme } from 'layout/Themes';
import { useMediaQuery } from '@mui/material';
import { getDesignTokens } from 'AppGlobals';


// var headline = createTheme({
//   typography: {
//     fontFamily: [
//       'Bungee Hairline',
//     ].join(','),
//     h1: {
//       fontSize: '7rem',
//       '@media (min-width:600px)': {
//         fontSize: '5rem',
//       },
//       [theme.breakpoints.up('xs')]: {
//         fontSize: '3.5rem',
//       },
//       [theme.breakpoints.up('sm')]: {
//         fontSize: '3.5rem',
//       },
//       [theme.breakpoints.up('md')]: {
//         fontSize: '4rem',
//       },
//     }
//   },
// });

// headline = responsiveFontSizes(headline);

type QuoteSplashProperties = {
  smallImage: string,
  bigImage: string,
  children: ReactNode | undefined
}

export const QuoteSplash: React.FC<QuoteSplashProperties> = ({ smallImage, bigImage, children}) => {
  const scaledHeight = { xs: "700px", sm: "500px", md: "800px", lg: "800px" };

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(
    getDesignTokens(
      prefersDarkMode ? "dark" : "light")
    ),
    [
      prefersDarkMode ? "dark" : "light"
    ]
  );

  return (
      <Box sx={{ 
        zIndex: "-999", 
        background: { 
          xs: 'url("' + smallImage + '")',
          sm: 'url("' + bigImage + '")',
          md: 'url("' + bigImage + '")',
        }, 
        backgroundSize: {
          xs: "auto 100%",
          sm: "cover",
          md: "cover",
          lg: "cover"
        },
        display: "block",
        height: scaledHeight
      }}>
        <Container maxWidth="lg">
          <Box sx={
            {
              position: "absolute",
              zIndex: "200",
              display: "block",
              alignItems: "center",
              padding: "20px",
              width: "60%",
              background: theme.palette.background.default
            }
          }>
            { children }
          </Box>
        </Container>
        { /* 
          <Box sx={{
            position: "absolute",
            height: scaledHeight,
            width: "100vw",
            backgroundColor: theme.palette.background.default,
            opacity: ".9",
            zIndex: "2"
          }}>
          </Box> 
        */ }
      </Box>
  )
}

export const QuoteSplashTitle: React.FC<{ children: ReactNode | undefined }> = ({children}) => {
  return (
    <ThemeProvider theme={ {} }>
      <Typography variant="h2">
        { children }
      </Typography>
    </ThemeProvider>
  );
}

export const QuoteSplashSubtitle: React.FC<{ children: ReactNode | undefined }> = ({children}) => {
  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h6" gutterBottom>
        { children }
      </Typography>
    </ThemeProvider>
  );
}
