import { Button, Container, Grid, createTheme, useMediaQuery } from "@mui/material";
import { getDesignTokens } from "AppGlobals";
import { QuoteSplash, QuoteSplashSubtitle, QuoteSplashTitle } from "layout/QuoteSplash";
import { Sectional } from "layout/Sectional";
import { SplashColumn, SplashColumnChild } from "layout/Splash";
import React from "react";
import { FundraiserProperties } from "widgets/Types/Types";

export const FundraiserWidget: React.FC<FundraiserProperties> = ({title = "Support Us Here!"}) => {
    return (
        <SplashColumn>
            <SplashColumnChild>
                {/* <Grid container direction="row" justify="center" alignItems="center">
                    <iframe
                    frameBorder="0"
                    height="408px"
                    width="432px"
                    title="MADE Fundraising Game"
                    src="https://www.themade.org/madefundpico.html?goal=5000.0000&funds=120.2773"
                    scrolling="no"
                    ></iframe>
                </Grid> */}
            </SplashColumnChild>
            <SplashColumnChild>
                <h4 className="title">
                    { title }
                </h4>
                <br />
                {/* <Button href="https://www.patreon.com/themade" formTarget="" style={ { width:"32%", margin: "0 1% 0 0" } }>
                    <img width="100%" src="img/sponsor/patreon.svg" style={{ color: "#fff", }} alt="Patreon: The MADE" />
                </Button>
                <Button href="/donations" formTarget="" style={ { width:"32%", margin: "0 1% 0 0" } }>
                    <img width="100%" src="img/icon/donate.svg" style={{ color: "#fff", }} alt="Paypal: The MADE"/>
                </Button> */}
            </SplashColumnChild>
        </SplashColumn>
    );
}

export const DonationOptionsWidget: React.FC<FundraiserProperties> = ({title = "Support Us Here!"}) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Update the theme only if the mode changes
    const theme = React.useMemo(() => createTheme(
        getDesignTokens(
        prefersDarkMode ? "dark" : "light")
        ),
        [
        prefersDarkMode ? "dark" : "light"
        ]
    );
    
    let color = prefersDarkMode ? "0%": "65%";


    return (
        <Sectional title="How to Donate">
            <Grid container>
                <Grid item xs={12} sm={4}>
                    <Button href="https://www.patreon.com/themade" formTarget="" style={ { width:"100%", margin: "0 0 0 0" } }>
                        <img width="100%" src="img/sponsor/patreon.svg" style={{ color: "#fff", filter: "invert(" + color + ")", }} alt="Patreon: The MADE" />
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button href="https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=9SR2A5FN7SXK8" formTarget="" style={ { width:"100%", margin: "0 0 0 0" } }>
                        <img width="100%" src="img/sponsor/paypal_white.svg" style={{ color: "#fff", filter: "invert(" + color + ")" }} alt="PayPal: The MADE" />
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button href="https://store.themade.org/" formTarget="" style={ { width:"100%", margin: "0 0 0 0" } }>
                        <img width="100%" src="img/icon/made_store.svg" style={{ color: "#fff", filter: "invert(" + color + ")", }} alt="Patreon: The MADE" />
                    </Button>
                </Grid>
            </Grid>
        </Sectional>
    );
}
