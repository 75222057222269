import { Box, Grid } from "@mui/material";
import { Sectional } from "layout/Sectional";
import { ColumnList } from "layout/Splash"
import React from "react";

type DonorsProperties = {
    key: Number,
    sponsors: { height: string; paddingTop: string; image: string; link: string; }[],
    corporate_donors: { title: string, link: string }[],
    donors: string[],
    kickstarters: string[]
}

export const DonorsWidget: React.FC<DonorsProperties> = ({sponsors, corporate_donors, donors, kickstarters}) => {
    var i = 0
    var j = 0
    var k = 0
    var l = 0

    return (
        <>
            <Sectional title="Sponsors">
                <Grid container>
                    {
                        sponsors.map((sponsor) => {  
                            return (
                                <Grid item sm={12} md={4}>
                                    <a href={sponsor.link}>
                                        <Box sx={{ "height": sponsor.height, marginBottom:"20px", backgroundColor: "whitesmoke" }} component="img" src={sponsor.image}>
                                        </Box>
                                    </a>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Sectional>
            <Sectional title="Corporate Donors">
                <Grid container>
                    {
                        corporate_donors.map((donor) => {  
                            return (
                                <Grid item xs={6} sm={6} md={4} lg={3}>
                                    <a href={ donor.link }>{ donor.title }</a>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Sectional>
            <Sectional title="Donors">
                <Grid container>
                    {
                        donors.map((donor) => {  
                            return (
                                <Grid item xs={6} sm={6} md={4} lg={3}>
                                    { donor }
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Sectional>
            <Sectional title="Kickstarter Backers">
                <Grid container>
                    {
                        kickstarters.map((kickstarter) => {  
                            return (
                                <Grid item xs={6} sm={6} md={4} lg={3}>
                                    { kickstarter }
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Sectional>
        </>
    );
}