import { Box, Container, Grid, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';

type PlayableExhibit = {
    name: string,
    status: string,
    image: string,
    description: string,
    bonus_fact: string
    audio: string
}

type ExhibitData = {
    exhibits: PlayableExhibit[];
    audio_tour_button_text: string;
}

type ExhibitsProperties = {
    data: ExhibitData;
    enableAudioTour: boolean;
}

export const ExhibitsWidget: React.FC<ExhibitsProperties> = ({ data, enableAudioTour }) => {
    // Filter exhibits with status "Playable"
    const { exhibits, audio_tour_button_text } = data;
    const playableExhibits = exhibits.filter(exhibit => exhibit.status === "Playable");
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const audioRefs = React.useRef({});
    const headerRefs = React.useRef({});

    let setAudio: ((idx: number) => void) | null = null;

    useEffect(() => {
        // Make sure the state is updated in this function.
        setAudio = (idx) => {
            if (idx < 0 || idx >= playableExhibits.length) {
                // Stop the tour.
                setCurrentIndex(0);
                return;
            }

            // Stop the current audio if it's playing.
            audioRefs.current[currentIndex]?.pause();

            // Scroll to the header of the current exhibit.
            headerRefs.current[idx]?.scrollIntoView({
                behavior: "smooth",
            });

            audioRefs.current[idx]?.play();

            setCurrentIndex(idx);
        };
    }, [currentIndex]);
    
    return (
        <Container sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "1em" }}>
            {enableAudioTour && <Button variant="contained" color="success" onClick={() => setAudio!(0)}>
                { audio_tour_button_text }
            </Button>}
            <Grid container spacing={2}>
                {playableExhibits.map((exhibit, idx) => (
                    <Grid item xs={12} md={6} key={exhibit.name}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                paddingTop: "50px",
                                height: "100%",
                            }}
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                                ref={el => headerRefs.current[idx] = el}
                                sx={{ scrollMarginTop: "5em" }}
                            >
                                {exhibit.name}
                            </Typography>
                            {exhibit.image && (
                                <img
                                    src={exhibit.image}
                                    alt={exhibit.name}
                                    style={{ maxWidth: "100%", height: "auto" }}
                                />
                            )}
                            <Typography sx={{ marginTop: 2 }}>
                                {exhibit.description}
                            </Typography>
                            {enableAudioTour && <Box sx={{ display: "flex", alignItems: "center" }}>
                                {
                                    idx !== 0 && currentIndex === idx && (
                                        <IconButton onClick={() => setAudio!(currentIndex - 1)}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                    )
                                }
                                <audio
                                    ref={el => audioRefs.current[idx] = el}
                                    onEnded={() => setAudio!(currentIndex + 1)}
                                    onPlay={currentIndex !== idx ? () => setAudio!(idx) : () => {}}
                                    controls
                                >
                                    <source src={exhibit.audio} type="audio/mpeg" />
                                    Your browser does not support the audio element.
                                </audio>
                                {
                                    idx !== playableExhibits.length - 1 && currentIndex === idx && (
                                        <IconButton onClick={() => setAudio!(currentIndex + 1)}>
                                            <ArrowForwardIcon />
                                        </IconButton>
                                    )
                                }
                            </Box>}
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

/*
export const ExhibitsWidget: React.FC<ExhibitsProperties> = ({ exhibits }) => {
    return (
        <>
            {
                exhibits.map((exhibit) => {
                    return (
                        <Sectional title={ exhibit.name }>
                            <Grid container spacing={2}>
                                <Grid item sm={5}>
                                    { exhibit.description }
                                </Grid>
                                <Grid item sm={7}>
                                    <Box sx={{ width: "100%"}} component="img" src={ exhibit.image }></Box>
                                </Grid>
                            </Grid>
                        </Sectional>
                    )
                })
            }
        </>
    );
}
*/
